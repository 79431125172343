@import url('https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap')

// font-family: 'Montserrat', sans-serif;
// font-family: 'Raleway', sans-serif;

$family-sans-serif: 'Raleway', sans-serif
$size-1: 2.6rem
$size-2: 2.10rem
$size-3: 1.6rem
$size-4: 1.35rem
$size-5: 1.10rem
$size-6: 0.85rem
$size-7: 0.60rem

$dark: hsla(249, 79%, 16%, 1)

$footer-padding: 2rem 1rem 2rem

@import 'bulma'
