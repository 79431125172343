.bgImageCover {
  /* background-image: url('/images/login-background.png'); */
  background-size: contain;
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
}

.bgImageDiamondCover {
  /* background-image: url('/images/background_diamond.jpg'); */
  background-size: auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.bgImageHeader {
  /* background-image: url('/images/login-background.png'); */
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.goldText {
  color: "#d7b460" !important;
}

.goldBorder { 
  border-bottom: "1px solid rgba(215,180,96,0.5)";
  border-top: "1px solid rgba(215,180,96,0.7)";
}

.goldBorderBottom { 
  border-bottom: "1px solid rgba(215,180,96,0.5)" !important;
}

.claimErrorEditTable {
  font-size: .80rem;
}

.claimErrorEditTable thead tr th {
  text-align: center;
  vertical-align: middle;
}

.claimErrorEditTable tbody tr td {
  min-width: 120px;
}

.darkBlueBackground {
  background: "#13094b !important"
}